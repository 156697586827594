import React from 'react';
import parse from 'react-html-parser';
import styles from './styles.module.scss';
import moment from 'moment';
import Link from 'components/Link';

import * as PropTypes from 'prop-types';

export default function PostRow({ uid, postData }) {
  const { title, release_date, lead_in } = postData,
    date = moment(release_date),
    dateStr = date.format(date.year() < moment().year() ? 'MMM D, YYYY' : 'MMM D'),
    showLeadIn = lead_in && lead_in.text;
  return (
    <Link to={`/blog/${uid}`} className={styles.postRow} key={`recent-post-${uid}`}>
      <span className={styles.postDate}>{dateStr}</span>
      <h3 className={styles.postTitle}>{title.text}</h3>
      {showLeadIn && <div className={styles.leadIn}>{parse(lead_in.html)}</div>}
    </Link>
  );
}

PostRow.propTypes = {
  uid: PropTypes.string,
  postData: PropTypes.object
};
