import React from 'react';
import Typing, { Backspace, Reset } from 'react-typing-animation';
import { Spring } from 'react-spring/renderprops';

import Layout from 'components/Layout';
import Helmet from 'components/Helmet';
import styles from './home.module.scss';
import { graphql } from 'gatsby';
import { SlideDown } from '../components/Transition';
import PostRow from '../components/PostRow';

const getDelay = () => 2000 * (1 + Math.random()),
  ATHLETES = ['violinists', 'cellists', 'mandolinists', 'bassists', 'violaists', 'fiddlers'];

const IndexPage = ({ data: { allPrismicPost } }) => {
  const { edges } = allPrismicPost;
  return (
    <Layout>
      <Helmet />
      <SlideDown>
        <div className={styles.homeRoot}>
          <div className={styles.introWrapper}>
            <h1 className={styles.introText}>
              Musings and resources for{' '}
              <Typing
                key={Math.random()}
                className={styles.typewriterText}
                cursor={false}
                loop={true}
                speed={30}
                startDelay={600}
              >
                {ATHLETES.map(athlete => (
                  <React.Fragment key={`athlete-${athlete}`}>
                    <span>{athlete}</span>
                    <Backspace count={athlete.length + 5} delay={getDelay()} />
                  </React.Fragment>
                ))}
                <Reset />
              </Typing>{' '}
              <br />
              and other tiny muscle athletes on finding balance, health, and joy in lifelong practice.
            </h1>
          </div>

          <Spring from={{ transform: 'translateY(1000%)' }} to={{ transform: 'translateY(0)' }} delay={200}>
            {props => (
              <div style={props} className={styles.mainColumn}>
                <div className={styles.recentPosts}>
                  <h2 className={styles.recentPostsHeader}>Recent posts</h2>
                  {edges.map(({ node }) => (
                    <PostRow key={`recent-post-${node.uid}`} uid={node.uid} postData={node.data} />
                  ))}
                </div>
              </div>
            )}
          </Spring>
        </div>
      </SlideDown>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query MostRecentPosts {
    allPrismicPost(
      limit: 5
      sort: { fields: [data___release_date, first_publication_date, last_publication_date], order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            release_date
            title {
              text
            }
            lead_in {
              html
              text
            }
          }
          first_publication_date
          last_publication_date
        }
      }
    }
  }
`;
